<template>
  <div v-if="error && userError == false" class="container">
    <h6>Something went wrong please try again...</h6>
    <code>{{ error }}</code>
    <button @click="retry()">Try again</button>
  </div>
  <div v-else-if="userError != false" class="container">
    <h6>{{ userError }}</h6>
  </div>
  <div v-else class="container">
    <img src="./assets/topper.svg">
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      init: false,
      error: false,
      userError: false,
      loading: true,
      userData: null,
      walletAddress: null,
      fiatType: 'USD',
      theme: false
    }
  },
  methods: {
    async retry() {
      try {
        if(!this.init) this.initUser()
        const url = await this.getRedirectUrl()
        this.redirect(url)
        this.$xapp.close()
      } catch(e) {
        this.error = e
        this.$xapp.ready()
      }
    },
    async getTokenData (ott) {
      const res = await fetch('https://xumm.app/api/v1/xapp-jwt/authorize', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'x-api-key': process.env.VUE_APP_XAPP_KEY,
          'x-api-ott': ott
          }
        })
        if(res.status < 200 || res.status > 299) throw res.status

        const data = await res.json()
        this.userData = data

        window.xappJWTxumm = data.jwt

        this.walletAddress = data.ott.account
        this.fiatType = data.ott.currency
        return this.userData
    },
    async initUser() {
      const urlParams = new URLSearchParams(window.location.search)
      const ott = urlParams.get('xAppToken')
      this.theme = urlParams.get('xAppStyle')
      const data = await this.getTokenData(ott)
      this.init = true
      return data
    },
    async getRedirectUrl() {
      const res = await fetch(process.env.VUE_APP_BACKEND_URL + '/url', {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          address: this.walletAddress,
          label: 'My wallet',
          currency: this.fiatType
        })
      })
      const data = await res.json()
      return data.url
    },
    redirect(url) {
      if(this.theme === 'LIGHT') url = url + '&theme=light'
      this.$xapp.openBrowser({ url })
      this.$xapp.close()
    },
    checkUserData() {
      const access = this.userData.ott.accountaccess
      const node = this.userData.ott.nodetype

      let error = false
      if(access !== 'FULL' && node !== 'MAINNET') error = 'This xApp cannot be opened with a "Read Only" account and with a NodeType is not set to MAINNET, please change this in the "Advanced" settings menu'
      if(access !== 'FULL') error = 'This xApp cannot be opened with a "Read Only" account, please switch to an account that is able to sign with "Full Access"'
      if(node !== 'MAINNET') error = 'This xApp cannot be opened with this NodeType, please change this in the "Advanced" settings menu to "MAINNET"'

      this.userError = error
      if(error != false) throw error
    },
  },
  async created() {
    try {
      await this.initUser()
      this.checkUserData()
      const url = await this.getRedirectUrl()
      this.redirect(url)
    } catch(e) {
      this.error = e
      this.$xapp.ready()
    }
    this.loading = false
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  height: 100vh;
  background-color: rgb(38, 50, 43);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  height: inherit;
  overflow: hidden;
  display: flex;
}
.container {
  display: flex;
  width: 200px;
  max-width: 500px;
  flex-direction: column;
  padding: 20px;
  margin: auto;
}
iframe {
  width: 1px;
  min-width: 100%;
  height: inherit;
}
</style>
